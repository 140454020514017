import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SummaryBar from "./SummaryBar";
import CaloriesSummary from "./CaloriesSummary";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

export default function Summary(props) {
  const classes = useStyles();

  return (
    <Grid container className={classes.mainContainer}>
      <SummaryBar />
      <Grid container>
        {/* Results Row */}
        <React.Fragment>
          <Grid item lg={2} md={2}></Grid>
          <Grid
            item
            lg={8}
            md={8}
            style={{ position: "relative", top: "-50px" }}
          >
            <CaloriesSummary calories={1820} />
          </Grid>
          <Grid item lg={2} md={2}></Grid>
        </React.Fragment>
      </Grid>
    </Grid>
  );
}
