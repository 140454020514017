import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Image from "material-ui-image";

const useStyles = makeStyles({
  mainContainer: {
    padding: 20,
    backgroundColor: "#2ec4b6",
    color: "white",
  },
});

export default function WelcomeBar() {
  const classes = useStyles();

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item md={2} lg={2} sm={0} xs={0}></Grid>
      <Grid item md={7} lg={7} sm={12} xs={12} style={{ width: "80%" }}>
        <Typography variant="h1" style={{ fontSize: 32 }}>
          How many calories should I burn?
        </Typography>
        <p>
          Calorie calculators, like the one below, are a great resource for
          quickly <br /> determining a weight loss plan based on calorie
          expenditure, activity levels,
          <br /> and overall goals.
        </p>
        <p>
          We measure your Basal Metabolic Rate and Total Daily Energy
          Expenditure (TDEE), and calculate your macros (carbs, fats, and
          protein).
        </p>
      </Grid>
      <Grid item md={3} lg={3} sm={12} xs={12}>
        <Image
          src="/train.svg"
          alt="Women holding weights"
          style={{ backgroundColor: "transparent" }}
        />
      </Grid>
    </Grid>
  );
}
