import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { PieChart, Pie, Sector, Cell, Tooltip } from "recharts";
import HelpIcon from "@material-ui/icons/Help";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { getAllNutritionTypes } from "../../helpers/calculate";

const carbDiets = {
  low: {
    label: "Lower Carb",
    description: ["40% protein", "40% fat", "20% carbs"],
    pie: [
      { name: "Protein", value: 40 },
      { name: "Fat", value: 40 },
      { name: "Carbs", value: 20 },
    ],
  },
  moderate: {
    label: "Moderate Carb",
    description: ["30% protein", "35% fat", "35% carbs"],
    pie: [
      { name: "Protein", value: 30 },
      { name: "Fat", value: 35 },
      { name: "Carbs", value: 35 },
    ],
  },
  high: {
    label: "Higher Carb",
    description: ["30% protein", "20% fat", "50% carbs"],
    pie: [
      { name: "Protein", value: 30 },
      { name: "Fat", value: 20 },
      { name: "Carbs", value: 50 },
    ],
  },
};

const useStyles = makeStyles({
  container: {
    padding: 30,
  },
  popover: {
    pointerEvents: "none",
  },
});

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#218380"}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export default function CaloriesSummary(props) {
  const [diet, setDiet] = React.useState("moderate");
  const [nutrition, setNutrition] = React.useState({
    protein: 0,
    fat: 0,
    carbs: 0,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDietChange = (event) => {
    setDiet(event.target.value);
    setNutrition(getAllNutritionTypes(props.calories, diet));
  };

  const open = Boolean(anchorEl);

  const classes = useStyles();

  return (
    <Paper elevation={3}>
      <Grid container className={classes.container}>
        <Grid item xs={6}>
          <Typography variant="h5">Your results</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            textAlign: "right",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography variant="h5">
            <strong>{props.calories}</strong>
            kcal
          </Typography>
          <HelpIcon
            color="primary"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          />
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography>I use Popover.</Typography>
          </Popover>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PieChart width={200} height={200}>
            <Pie
              activeIndex={0}
              activeShape={renderActiveShape}
              data={[
                { name: `${props.calories} Calories`, value: props.calories },
              ]}
              innerRadius={60}
              outerRadius={80}
              fill="#2ec4b6"
              dataKey="value"
            />
          </PieChart>
        </Grid>

        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="h5">Daily macros</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              Diet Type
              <Select
                value={diet}
                onChange={handleDietChange}
                style={{ marginLeft: 10, color: "#218380" }}
              >
                <MenuItem value={"low"}>{carbDiets["low"].label}</MenuItem>
                <MenuItem value={"moderate"}>
                  {carbDiets["moderate"].label}
                </MenuItem>
                <MenuItem value={"high"}>{carbDiets["high"].label}</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              marginTop: 50,
              height: 150,
              justifyContent: "space-around",
            }}
          >
            <Paper
              elevation={2}
              style={{
                height: "100%",
                borderRadius: 10,
                width: 130,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="/egg.svg"
                height="40px"
                width="40px"
                alt="egg"
                style={{ position: "relative", top: "-20px" }}
              ></img>
              <div
                style={{
                  height: "60%",
                  display: "flex",
                  alignItems: "center",
                  fontSize: 32,
                  fontWeight: 500,
                }}
              >
                {nutrition.protein
                  ? nutrition.protein
                  : getAllNutritionTypes(props.calories, diet).protein}
                g
              </div>
              <div
                style={{
                  height: "40%",
                  display: "flex",
                  alignItems: "flex-start",
                  color: "gray",
                }}
              >
                Protein
              </div>
            </Paper>
            <Paper
              elevation={2}
              style={{
                height: "100%",
                borderRadius: 10,
                width: 130,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="/avocado.svg"
                height="40px"
                width="40px"
                alt="avocado"
                style={{ position: "relative", top: "-20px" }}
              ></img>
              <div
                style={{
                  height: "60%",
                  display: "flex",
                  alignItems: "center",
                  fontSize: 32,
                  fontWeight: 500,
                }}
              >
                {nutrition.fat
                  ? nutrition.fat
                  : getAllNutritionTypes(props.calories, diet).fat}
                g
              </div>
              <div
                style={{
                  height: "40%",
                  display: "flex",
                  alignItems: "flex-start",
                  color: "gray",
                }}
              >
                Fat
              </div>
            </Paper>
            <Paper
              elevation={2}
              style={{
                height: "100%",
                borderRadius: 10,
                width: 130,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="/wheat.svg"
                height="40px"
                width="40px"
                alt="wheat"
                style={{ position: "relative", top: "-20px" }}
              ></img>
              <div
                style={{
                  height: "60%",
                  display: "flex",
                  alignItems: "center",
                  fontSize: 32,
                  fontWeight: 500,
                }}
              >
                {nutrition.carbs
                  ? nutrition.carbs
                  : getAllNutritionTypes(props.calories, diet).carbs}
                g
              </div>
              <div
                style={{
                  height: "40%",
                  display: "flex",
                  alignItems: "flex-start",
                  color: "gray",
                }}
              >
                Carbs
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12} md={12} style={{ marginTop: 50 }}>
          <Typography variant="h5">Our formula for you</Typography>
        </Grid>

        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid item xs={7} style={{ paddingTop: 30 }}>
            <Typography componentt={"p"}>
              If you are counting macros for bodybuilding and muscle gain, you
              will want to add overall calories to put weight. Try this range of
              macro ratio: <strong>{carbDiets[diet].description[2]}</strong>,{" "}
              <strong>{carbDiets[diet].description[0]}</strong>,{" "}
              <strong>{carbDiets[diet].description[1]}</strong>.
            </Typography>
            <Button
              color="secondary"
              variant="outlined"
              style={{ marginTop: 20, width: "60%" }}
            >
              <Link to="/summary" style={{ color: "gray" }}>
                {" "}
                Find out more
              </Link>
            </Button>
          </Grid>
          <Grid item xs={5}>
            <PieChart width={300} height={200}>
              <Pie
                data={carbDiets[diet].pie}
                labelLine={false}
                cx="50%"
                cy="40%"
                label={renderCustomizedLabel}
                outerRadius={80}
                dataKey="value"
              >
                {carbDiets[diet].pie.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value) => `${value}%`} />
            </PieChart>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
