import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Slider from "@material-ui/core/Slider";
import Paper from "@material-ui/core/Paper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  ageMarks,
  heightMarks,
  metricWeightMarks,
  usaWeightMarks,
} from "../helpers/marks";
import { InputBase } from "@material-ui/core";

const activities = {
  sedentary: "Sedentary (office job)",
  light: "Light Exercise (1-2 days/week)",
  moderate: "Moderate Exercise (3-5 days/week)",
  hard: "Hard Exercise (6-7 days/week)",
  athlete: "Athlete (2x per day)",
};

const useStyles = makeStyles({
  container: {
    padding: 30,
  },
  genderButtonGroup: {
    display: "flex",
    width: "100%",
  },
  genderButton: {
    flex: 1,
  },
  wishContainer: {
    marginTop: 20,
    width: "100%",
  },
  wishButtonGroup: {
    marginTop: 10,
    display: "flex",
    height: "50px",
    width: "100%",
  },
  wishButton: { flex: 1, fontSize: 12 },
  actionContainer: {
    marginTop: 40,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  input: {
    marginLeft: 20,
  },
});

export default function FormCalculator(props) {
  const classes = useStyles();
  const [age, setAge] = React.useState(26);
  const [metricWeight, setMetricWeight] = React.useState(79);
  const [usaWeight, setUSAWeight] = React.useState(174.165);

  const [metricHeight, setMetricHeight] = React.useState(163);
  const [feetHeight, setFeetHeight] = React.useState(5);
  const [inchesHeight, setInchesHeight] = React.useState(3.47);

  const [gender, setGender] = React.useState("female");
  const [activity, setActivity] = React.useState("sedentary");
  const [wish, setWish] = React.useState("lose");
  const [measurement, setMeasurement] = React.useState("metric");

  const handleAgeSliderChange = (event, newValue) => {
    setAge(newValue);
  };

  const handleMetricHeightSliderChange = (event, newValue) => {
    setMetricHeight(newValue);
  };

  const handleMetricWeightSliderChange = (event, newValue) => {
    setMetricWeight(newValue);
  };

  const handleUSAWeightSliderChange = (event, newValue) => {
    setUSAWeight(newValue);
  };

  const handleAgeInputChange = (event) => {
    setAge(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleMetricHeightInputChange = (event) => {
    setMetricHeight(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleFeetHeightInputChange = (event) => {
    setFeetHeight(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleInchesHeightInputChange = (event) => {
    setInchesHeight(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleMetricWeightInputChange = (event) => {
    setMetricWeight(
      event.target.value === "" ? "" : Number(event.target.value)
    );
  };

  const handleUSAWeightInputChange = (event) => {
    setUSAWeight(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleGenderChange = (value) => {
    setGender(value);
  };

  const handleActivityChange = (event) => {
    setActivity(event.target.value);
  };

  const handleWishChange = (value) => {
    setWish(value);
  };

  const handleSwitchChange = (event) => {
    setMeasurement(event.target.checked ? "metric" : "imperial");
  };

  const calculateClick = () => {
    if (measurement === "metric") {
      props.handleCalculateClick({
        age,
        gender,
        activity,
        height: metricHeight,
        weight: metricWeight,
        wish,
      });
    } else {
      props.handleCalculateClick({
        age,
        gender,
        activity,
        height:
          Math.round(feetHeight * 30.48) + Math.round(inchesHeight * 2.54),
        weight: Math.round(usaWeight * 0.453592),
        wish,
      });
    }
  };

  return (
    <Paper elevation={3}>
      <Grid container className={classes.container} justify="flex-end">
        {/* MEASUREMENT - METRIC / IMPERIAL */}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-end", height: 36 }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={measurement === "metric" ? true : false}
                onChange={handleSwitchChange}
                name="measurement"
              />
            }
            label={measurement === "metric" ? "Metric" : "Imperial"}
            labelPlacement="start"
          />
        </Grid>

        {/* GENDER */}
        <Grid
          item
          xs={12}
          style={{ display: "inline-flex", alignItems: "center" }}
        >
          <Grid item xs={1} style={{ marginRight: 10 }}>
            I am a
          </Grid>
          <Grid item xs={5}>
            <ButtonGroup
              color="secondary"
              className={classes.genderButtonGroup}
              style={{ height: 36 }}
            >
              <Button
                className={classes.genderButton}
                variant={gender === "female" ? "contained" : "outlined"}
                onClick={() => handleGenderChange("female")}
                style={{ textTransform: "lowercase" }}
              >
                Female
              </Button>
              <Button
                className={classes.genderButton}
                variant={gender === "male" ? "contained" : "outlined"}
                onClick={() => handleGenderChange("male")}
                style={{ textTransform: "lowercase" }}
              >
                Male
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>

        {/* AGE */}
        <Grid item xs={12} style={{ display: "inline-flex", marginTop: 20 }}>
          <Typography component={"span"}>
            I am
            <InputBase
              className={classes.input}
              value={age}
              margin="dense"
              onChange={handleAgeInputChange}
              onBlur={handleAgeInputChange}
              style={{ color: "#218380" }}
              inputProps={{
                step: 1,
                min: 0,
                max: 200,
                type: "number",
                "aria-label": "naked",
              }}
            />
            years old.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Slider
            value={typeof age === "number" ? age : 0}
            onChange={handleAgeSliderChange}
            min={0}
            step={1}
            max={100}
            marks={ageMarks}
            valueLabelDisplay="auto"
            aria-labelledby="non-linear-slider"
          />
        </Grid>

        {/* WEIGHT */}
        <Grid item xs={12} style={{ display: "inline-flex", marginTop: 10 }}>
          {measurement === "metric" ? (
            <Typography component={"span"}>
              Current weight is
              <InputBase
                className={classes.input}
                value={metricWeight}
                margin="dense"
                onChange={handleMetricWeightInputChange}
                onBlur={handleMetricWeightInputChange}
                style={{ color: "#218380" }}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 500,
                  type: "number",
                  "aria-label": "naked",
                }}
              />
              kg.
            </Typography>
          ) : (
            <Typography component={"span"}>
              Current weight is
              <InputBase
                className={classes.input}
                value={usaWeight}
                margin="dense"
                onChange={handleUSAWeightInputChange}
                onBlur={handleUSAWeightInputChange}
                style={{ color: "#218380" }}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 500,
                  type: "number",
                  "aria-label": "naked",
                }}
              />
              lbs.
            </Typography>
          )}
        </Grid>
        {measurement === "metric" ? (
          <Grid item xs={12}>
            <Slider
              value={typeof metricWeight === "number" ? metricWeight : 0}
              onChange={handleMetricWeightSliderChange}
              min={0}
              step={1}
              max={300}
              marks={metricWeightMarks}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Slider
              value={typeof usaWeight === "number" ? usaWeight : 0}
              onChange={handleUSAWeightSliderChange}
              min={0}
              step={1}
              max={300}
              marks={usaWeightMarks}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
          </Grid>
        )}

        {/* HEIGHT */}
        <Grid item xs={12} style={{ display: "inline-flex", marginTop: 10 }}>
          {measurement === "metric" ? (
            <Typography component={"span"}>
              My height is
              <InputBase
                className={classes.input}
                value={metricHeight}
                margin="dense"
                onChange={handleMetricHeightInputChange}
                onBlur={handleMetricHeightInputChange}
                style={{ color: "#218380" }}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 300,
                  type: "number",
                  "aria-label": "naked",
                }}
              />
              cm.
            </Typography>
          ) : (
            <Typography component={"span"}>
              My height is
              <InputBase
                className={classes.input}
                value={feetHeight}
                margin="dense"
                onChange={handleFeetHeightInputChange}
                onBlur={handleFeetHeightInputChange}
                style={{ color: "#218380" }}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 300,
                  type: "number",
                  "aria-label": "naked",
                }}
              />
              ft{" "}
              <InputBase
                className={classes.input}
                value={inchesHeight}
                margin="dense"
                onChange={handleInchesHeightInputChange}
                onBlur={handleInchesHeightInputChange}
                style={{ color: "#218380" }}
                inputProps={{
                  step: 1,
                  min: 0,
                  max: 300,
                  type: "number",
                  "aria-label": "naked",
                }}
              />
              inches.
            </Typography>
          )}
        </Grid>
        {measurement === "metric" ? (
          <Grid item xs={12}>
            <Slider
              value={typeof metricHeight === "number" ? metricHeight : 0}
              onChange={handleMetricHeightSliderChange}
              min={0}
              step={1}
              max={250}
              marks={heightMarks}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
          </Grid>
        ) : (
          ""
        )}

        {/* ACTIVITY */}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 15,
            marginBottom: 15,
          }}
        >
          <Typography>Activity Level</Typography>
          <Select
            value={activity}
            onChange={handleActivityChange}
            style={{ marginLeft: 10, color: "#218380" }}
          >
            <MenuItem value={"sedentary"}>{activities["sedentary"]}</MenuItem>
            <MenuItem value={"light"}>{activities["light"]}</MenuItem>
            <MenuItem value={"moderate"}>{activities["moderate"]}</MenuItem>
            <MenuItem value={"heavy"}>{activities["heavy"]}</MenuItem>
            <MenuItem value={"athlete"}>{activities["athlete"]}</MenuItem>
          </Select>
        </Grid>

        {/* I WISH TO */}
        <Grid
          item
          xs={12}
          style={{ display: "inline-flex", alignItems: "center" }}
        >
          <Grid item xs={2}>
            I want to
          </Grid>
          <Grid item xs={8}>
            <ButtonGroup
              color="secondary"
              style={{ width: "100%", height: 36 }}
            >
              <Button
                className={classes.wishButton}
                variant={wish === "lose" ? "contained" : "outlined"}
                onClick={() => handleWishChange("lose")}
                style={{ textTransform: "lowercase" }}
              >
                Lose
              </Button>
              <Button
                className={classes.wishButton}
                variant={wish === "maintain" ? "contained" : "outlined"}
                onClick={() => handleWishChange("maintain")}
                style={{ textTransform: "lowercase" }}
              >
                Maintain
              </Button>
              <Button
                className={classes.wishButton}
                variant={wish === "gain" ? "contained" : "outlined"}
                onClick={() => handleWishChange("gain")}
                style={{ textTransform: "lowercase" }}
              >
                Gain
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "end" }}>
            weight.
          </Grid>
        </Grid>

        {/* ACTION CONTAINER */}
        <div className={classes.actionContainer}>
          <Button
            variant="contained"
            className={classes.actionbutton}
            color="primary"
            onClick={() => calculateClick()}
          >
            Calculate
          </Button>
        </div>
      </Grid>
    </Paper>
  );
}
