import React from "react";
import Grid from "@material-ui/core/Grid";
import WelcomeBar from "./WelcomeBar";
import Calories from "./Calories";
import FormCalculator from "./FormCalculator";

export default function Home() {
  const [bmr, setBMR] = React.useState({});
  const [activity, setActivity] = React.useState("sedentary");
  const [showResult, setShowResult] = React.useState(false);

  const handleFormValues = (value) => {
    setBMR({
      calories: value.calories,
      gender: value.gender,
      age: value.age,
      height: value.height,
      weight: value.weight,
    });
    setActivity(value.activity);
    setShowResult(true);
  };

  return (
    <Grid container>
      <WelcomeBar />
      <Grid container>
        {/* Calculator Row */}
        <Grid item lg={2} md={2} sm={0} xs={0}></Grid>
        <Grid
          item
          lg={8}
          md={8}
          sm={12}
          xs={12}
          style={{ position: "relative", top: "-50px" }}
        >
          <FormCalculator handleCalculateClick={handleFormValues} />
        </Grid>
        <Grid item lg={2} md={2} sm={0} xs={0}></Grid>

        {/* Results Row */}
        {showResult ? (
          <React.Fragment>
            <Grid item lg={2} md={2} sm={0} xs={0}></Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <Calories bmr={bmr} activity={activity} />
            </Grid>
            <Grid item lg={2} md={2} sm={0} xs={0}></Grid>
          </React.Fragment>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}
