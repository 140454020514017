import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Image from "material-ui-image";

const useStyles = makeStyles({
  mainContainer: {
    padding: 20,
    backgroundColor: "#2ec4b6",
    color: "white",
  },
});

export default function SummaryBar() {
  const classes = useStyles();

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item md={2} lg={2}></Grid>
      <Grid item md={7} lg={7} style={{ width: "80%" }}>
        <Typography variant="h1" style={{ fontSize: 32 }}>
          Your caloriers summary
        </Typography>
      </Grid>
      <Grid item md={3} lg={3}>
        <Image src="/train.svg" style={{ backgroundColor: "transparent" }} />
      </Grid>
    </Grid>
  );
}
