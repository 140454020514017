import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Image from "material-ui-image";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  mainContainer: {
    padding: 20,
    backgroundColor: "#2ec4b6",
    color: "white",
  },
}));

export default function About(props) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid container className={classes.mainContainer}>
        <Grid item md={2} lg={2} xs={0}></Grid>
        <Grid item md={7} lg={7} xs={12} style={{ width: "80%" }}>
          <Typography variant="h1" style={{ fontSize: 32 }}>
            About Us
          </Typography>
          <p>
            Calorie calculators like ours are a great resource for quickly{" "}
            <br />
            determining a weight loss plan based on calorie expenditure,
            <br />
            activity levels, and other relevant information.
          </p>
          <p>
            If you want to learn how to manually calculate calories, <br /> read
            the steps below.
          </p>
        </Grid>
        <Grid item md={3} lg={3} xs={12}>
          <Image src="/train.svg" style={{ backgroundColor: "transparent" }} />
        </Grid>
      </Grid>
      <Grid container>
        {/* Results Row */}
        <React.Fragment>
          <Grid item lg={2} md={2} xs={0}>
            {" "}
          </Grid>
          <Grid
            item
            lg={8}
            md={8}
            xs={12}
            style={{ position: "relative", top: "-50px" }}
          >
            <Paper elevation={3} style={{ padding: 30 }}>
              <h1>How to calculate calories in 4 steps</h1>
              <h2>Step 1: Calculate BMR</h2>
              <p>
                Determine your <strong>basal metabolic rate (BMR)</strong> by
                using the {""} <strong>Mifflin-St Jeor equation</strong>:
              </p>
              <ul>
                <li>
                  <strong>Men</strong>: (10 × weight in kg) + (6.25 × height in
                  cm) - (5 × age in years) + 5
                </li>
                <li>
                  <strong>Women</strong>: (10 × weight in kg) + (6.25 × height
                  in cm) - (5 × age in years) - 161
                </li>
              </ul>

              <h2>Step 2: Calculate Total Daily Energy Expenditure</h2>
              <p>
                Measure <strong>Total Daily Energy Expenditure (TDEE)</strong>{" "}
                by multiplying your BMR by the{" "}
                <strong>Katch-McArdle multipliers</strong>:
              </p>
              <ul>
                <li>
                  <strong>Sedentary</strong> (little to no exercise + work a
                  desk job) = 1.2
                </li>
                <li>
                  <strong>Lightly Active</strong> (light exercise 1-2 days /
                  week) = 1.375
                </li>
                <li>
                  <strong>Moderately Active</strong> (moderate exercise 3-5 days
                  / week) = 1.55
                </li>
                <li>
                  <strong>Very Active</strong> (heavy exercise 6-7 days / week)
                  = 1.725
                </li>
                <li>
                  <strong>Extremely Active/Athlete</strong> (strenuous training
                  2x / day) = 1.9
                </li>
              </ul>

              <h2>Step 3: Determine Goal Calories</h2>
              <p>
                If you want to <strong>lose weight</strong>, you need to
                introduce a 500 calorie deficit from your maintenance calories.{" "}
                <br />
                <br />
                If you want to <strong>gain weight</strong>, you need to
                introduce a 500 calorie surplus from your maintenance calories.
              </p>

              <h2>Step 4: Calculate Macronutrient Ratios</h2>
              <p>
                After understanding the amount of calories you should eat during
                a day, you can start calculating also your macronutrients
                rations.
              </p>
              <p>
                Depending on the type of diet you want to follow and the
                protein, fat and carbs percentage relevant, we can make some
                calculations. For example, in a{" "}
                <strong>moderate carb diet</strong>, you have{" "}
                <strong>30% protein</strong>, <strong>35% fat</strong> and{" "}
                <strong>35% carbs</strong>.
              </p>
              <ul>
                <li>
                  Protein is calculated as <strong>(calories * 0.3)/4</strong>{" "}
                  because there are 4 calories per gram of protein.{" "}
                </li>
                <li>
                  Fat is calculated as <strong>(calories * 0.35)/4</strong>{" "}
                  because there are 9 calories per gram of fat.{" "}
                </li>
                <li>
                  Carbs is calculated as <strong>(calories * 0.35)/4</strong>{" "}
                  because there are 4 calories per gram of carbs.{" "}
                </li>
              </ul>
            </Paper>
          </Grid>
          <Grid item lg={2} md={2} xs={0}></Grid>
        </React.Fragment>
      </Grid>
    </Grid>
  );
}
