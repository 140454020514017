export function getAllNutritionTypes(calories, type) {
  if (type === "low") {
    return getNutrition(calories, { protein: 40, fat: 40, carbs: 20 });
  }

  if (type === "moderate") {
    return getNutrition(calories, { protein: 30, fat: 35, carbs: 35 });
  }

  if (type === "high") {
    return getNutrition(calories, { protein: 30, fat: 20, carbs: 50 });
  }

  return getNutrition(calories, { protein: 30, fat: 35, carbs: 35 });
}

/*
 * 1 protein/ 1 carb 4 calories
 * 1 fat 9 calories
 */

function getNutrition(calories, percentages) {
  const proteinCalories = calories * (percentages.protein / 100);
  const fatCalories = calories * (percentages.fat / 100);
  const carbsCalories = calories * (percentages.carbs / 100);

  return {
    protein: Math.round(proteinCalories / 4),
    fat: Math.round(fatCalories / 9),
    carbs: Math.round(carbsCalories / 4),
  };
}

//BMR Basal Metabolic Rate
//Mifflin = (10.m + 6.25h - 5.0a) + s
//m is mass in kg, h is height in cm, a is age in years, s is +5 for males and -151 for females

export function calculateCaloriesMetric(bmrInfo, activity) {
  const bmr = calculateBMRMetric(
    bmrInfo.age,
    bmrInfo.weight,
    bmrInfo.height,
    bmrInfo.gender
  );
  return Math.round(calculateTDEE(bmr, activity));
}

function calculateTDEE(bmr, activity) {
  if (activity === "sedentary") {
    return bmr * 1.2;
  }

  if (activity === "light") {
    return bmr * 1.375;
  }

  if (activity === "moderate") {
    return bmr * 1.55;
  }

  if (activity === "heavy") {
    return bmr * 1.725;
  }

  if (activity === "athlete") {
    return bmr * 1.9;
  }

  return bmr;
}

function calculateBMRMetric(age, weight, height, gender) {
  const s = gender === "female" ? -161 : 5;
  return Math.round(10 * weight) + Math.round(6.25 * height) - 5 * age + s;
}
