export const ageMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 100,
    label: "100",
  },
];

export const heightMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 150,
    label: "150",
  },
  {
    value: 180,
    label: "180",
  },
  {
    value: 200,
    label: "200",
  },
];

export const metricWeightMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 100,
    label: "100",
  },
];

export const usaWeightMarks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 132,
    label: "132",
  },
  {
    value: 176,
    label: "176",
  },
  {
    value: 220,
    label: "220",
  },
];
