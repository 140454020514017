import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { PieChart, Pie, Sector, Cell, Tooltip } from "recharts";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import {
  calculateCaloriesMetric,
  getAllNutritionTypes,
} from "../helpers/calculate";

const carbDiets = {
  low: {
    label: "Lower Carb",
    description: ["40% protein", "40% fat", "20% carbs"],
    pie: [
      { name: "Protein", value: 40 },
      { name: "Fat", value: 40 },
      { name: "Carbs", value: 20 },
    ],
  },
  moderate: {
    label: "Moderate Carb",
    description: ["30% protein", "35% fat", "35% carbs"],
    pie: [
      { name: "Protein", value: 30 },
      { name: "Fat", value: 35 },
      { name: "Carbs", value: 35 },
    ],
  },
  high: {
    label: "Higher Carb",
    description: ["30% protein", "20% fat", "50% carbs"],
    pie: [
      { name: "Protein", value: 30 },
      { name: "Fat", value: 20 },
      { name: "Carbs", value: 50 },
    ],
  },
};

const useStyles = makeStyles({
  container: {
    padding: 30,
  },
  popover: {
    pointerEvents: "none",
  },
});

const COLORS = ["#FFDA00", "#9AD166", "#FBAC31"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const renderActiveShape = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#218380"}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

export default function Calories(props) {
  const [calories] = React.useState(
    calculateCaloriesMetric(props.bmr, props.activity)
  );
  const [diet, setDiet] = React.useState("moderate");
  const [nutrition, setNutrition] = React.useState(
    getAllNutritionTypes(calories, diet)
  );

  const handleDietChange = (event) => {
    setDiet(event.target.value);
    setNutrition(getAllNutritionTypes(calories, event.target.value));
  };

  const classes = useStyles();

  return (
    <Paper elevation={3}>
      <Grid container className={classes.container}>
        <Grid item xs={6}>
          <Typography variant="h5">Your results</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            textAlign: "right",
            alignItems: "center",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Typography variant="h5">
            <strong>{calories}</strong>
            kcal
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PieChart width={200} height={200}>
            <Pie
              activeIndex={0}
              activeShape={renderActiveShape}
              data={[{ name: `${calories} Calories`, value: calories }]}
              innerRadius={60}
              outerRadius={80}
              fill="#2ec4b6"
              dataKey="value"
            />
          </PieChart>
        </Grid>

        <Grid item xs={12}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid item s={6} xs={12}>
              <Typography variant="h5">Daily macros</Typography>
            </Grid>
            <Grid
              item
              s={6}
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              Diet Type
              <Select
                value={diet}
                onChange={handleDietChange}
                style={{ marginLeft: 10, color: "#218380" }}
              >
                <MenuItem value={"low"}>{carbDiets["low"].label}</MenuItem>
                <MenuItem value={"moderate"}>
                  {carbDiets["moderate"].label}
                </MenuItem>
                <MenuItem value={"high"}>{carbDiets["high"].label}</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              marginTop: 50,
              height: 150,
              justifyContent: "space-around",
            }}
          >
            <Paper
              elevation={2}
              style={{
                height: "100%",
                borderRadius: 10,
                width: 130,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="/egg.svg"
                height="40px"
                width="40px"
                alt="egg"
                style={{ position: "relative", top: "-20px" }}
              ></img>
              <div
                style={{
                  height: "60%",
                  display: "flex",
                  alignItems: "center",
                  fontSize: 32,
                  fontWeight: 500,
                }}
              >
                {nutrition.protein} g
              </div>
              <div
                style={{
                  height: "40%",
                  display: "flex",
                  alignItems: "flex-start",
                  color: "gray",
                }}
              >
                Protein
              </div>
            </Paper>
            <Paper
              elevation={2}
              style={{
                height: "100%",
                borderRadius: 10,
                width: 130,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="/avocado.svg"
                height="40px"
                width="40px"
                alt="avocado"
                style={{ position: "relative", top: "-20px" }}
              ></img>
              <div
                style={{
                  height: "60%",
                  display: "flex",
                  alignItems: "center",
                  fontSize: 32,
                  fontWeight: 500,
                }}
              >
                {nutrition.fat} g
              </div>
              <div
                style={{
                  height: "40%",
                  display: "flex",
                  alignItems: "flex-start",
                  color: "gray",
                }}
              >
                Fat
              </div>
            </Paper>
            <Paper
              elevation={2}
              style={{
                height: "100%",
                borderRadius: 10,
                width: 130,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src="/wheat.svg"
                height="40px"
                width="40px"
                alt="wheat"
                style={{ position: "relative", top: "-20px" }}
              ></img>
              <div
                style={{
                  height: "60%",
                  display: "flex",
                  alignItems: "center",
                  fontSize: 32,
                  fontWeight: 500,
                }}
              >
                {nutrition.carbs} g
              </div>
              <div
                style={{
                  height: "40%",
                  display: "flex",
                  alignItems: "flex-start",
                  color: "gray",
                }}
              >
                Carbs
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={12} md={12} style={{ marginTop: 50 }}>
          <Typography variant="h5">Our formula for you</Typography>
        </Grid>

        <Grid item xs={12} style={{ display: "flex" }}>
          <Grid item md={7} xs={7} style={{ paddingTop: 30 }}>
            <Typography componentt={"p"}>
              If you are counting macros for bodybuilding and muscle gain, you
              will want to add overall calories to put weight. Try this range of
              macro ratio: <strong>{carbDiets[diet].description[2]}</strong>,{" "}
              <strong>{carbDiets[diet].description[0]}</strong>,{" "}
              <strong>{carbDiets[diet].description[1]}</strong>.
            </Typography>
            <br />
            <Typography componentt={"p"}>
              Check out our{" "}
              <Link
                to="/about"
                style={{
                  color: "#2ec4b6",
                  fontFamily: `"Roboto", sans-serif !important`,
                }}
              >
                <strong>About</strong>
              </Link>{" "}
              page for more information about our calculation.
            </Typography>
          </Grid>
          <Grid item md={5} xs={9}>
            <PieChart width={300} height={200} style={{ paddingTop: 30 }}>
              <Pie
                data={carbDiets[diet].pie}
                labelLine={false}
                cx="50%"
                cy="40%"
                label={renderCustomizedLabel}
                outerRadius={80}
                dataKey="value"
              >
                {carbDiets[diet].pie.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip formatter={(value) => `${value}%`} />
            </PieChart>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
