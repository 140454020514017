import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div className="footer-left">
        <Typography component={"p"}>
          {" "}
          <a href="mailto:boredpandacoder@gmail.com">
            Copyright © BoredPandaCoder
          </a>
        </Typography>
      </div>
      <div className="footer-right" style={{ display: "flex" }}>
        <Typography component={"p"} style={{ marginRight: 20 }}>
          <a
            href="https://www.freeprivacypolicy.com/live/237fec91-fc66-47d9-b3a4-94bd8244be6a"
            target="_blank"
            rel="noreferrer"
          >
            Privacy
          </a>
        </Typography>
        <Typography component={"p"}>
          <Link to="/about">About Us</Link>
        </Typography>
      </div>
    </footer>
  );
}
